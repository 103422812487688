function increaseTime()
{
	var time = $('#active-time-value').val();
	time = Math.abs(time) + 1;
	$('#active-time-value').val(time);
	$('#active-time').html(timeFormatter(time, true));

	var weeklyTime = $('#weekly-time-value').val();
	weeklyTime = Math.abs(weeklyTime) + 1;
	$('#weekly-time-value').val(weeklyTime);
	$('#weekly-time').html(timeFormatter(weeklyTime, false));
}

function timeFormatter(time, showSeconds)
{
	var hr = Math.floor(time / 3600);
	var min = Math.floor((time % 3600) / 60);
	var sec = Math.floor(time % 60);
	min = min < 10 ? (0 + min.toString()) : min;
	sec = sec < 10 ? (0 + sec.toString()).toString() : sec;

	return (hr || !showSeconds ? (hr + ':') : '') + min + (showSeconds ? (':' + sec) : '');
}

(function($) {

	var clockWidget = function() {
		var self = this;
	};

	clockWidget.prototype = {
		button: '.time-tracking .tracker-button',
		timer: false,
		isClockedIn: false,

		init: function() {
			var self = this;
			self.isNotLoggedUser = false;
			self.bindActions();
		},

		bindActions: function() {
			var self = this;

			$(this.button).on("click", function(e) {
				// $('.switch-user').hide();
				self.resetTrackerForm();
				$('.timeclock .pin').show();
				var controls = $('.timeclock');
				controls.find('.pin form, .pin').find('input[type="password"]').trigger('focus');
			});

			$('.timeclock .pin form').on('submit', function(e) {
				e.preventDefault();

				$.ajax({
					url: '/ajax/timesheet/login',
					data: $('.timeclock .pin form').serialize(),
					type: 'POST',
					success: function(response) {
						$('.timeclock .pin .error').remove();
						if (response.success) {
							var timeStatistics = $('.timeclock .stat');
							var $controls = $('.timeclock');
							timeStatistics.find('.day').html(timeFormatter(response.daylyTime));
							timeStatistics.find('.week').html(timeFormatter(response.weeklyTime));
							self.isClockedIn = response.active > 0;

							$controls.find('.staff-label').html(response.name);
							if (response.active > 0) {
								timeStatistics.find('.status-label').removeClass('stopped').addClass('started').html('In');
								timeStatistics.find('button.clock').addClass('btn-danger').html('Clock Out');
								$controls.find('.confirmation p').html('Are you sure you want to clock out?');
							} else {
								timeStatistics.find('.status-label').removeClass('started').addClass('stopped').html('Out');
								timeStatistics.find('button.clock').removeClass('btn-danger').html('Clock In');
								$controls.find('.confirmation p').html('Are you sure you want to clock in?');
							}
							timeStatistics.show();
							$('.timeclock .pin').hide();
						} else {
							$('.timeclock .pin form').alert();
							if(response?.message?.errorCode=='error_dup_pin') $('.clock-username.hide').removeClass('hide');
							$('.timeclock .pin form').alert(response?.message?.['password']?.[0] ?? response?.message, 'error');
						}
					},
					dataType: 'json'
				});
			});

			$('.timeclock .stat button.start').on('click', function(e) {
				e.preventDefault();
				$('.timeclock .stat').hide();
				$('.timeclock .confirmation').show();
			});

			$('.timeclock .confirmation button.accept').on('click', function(e) {
				$.ajax({
					url: '/ajax/timesheet/toggle',
					success: function(response) {
						self.resetTrackerForm();
						if (response.success) {
							if (self.isClockedIn) {
								$('.timeclock').alert('Time clock stopped', 'success');
							} else {
								$('.timeclock').alert('Time clock started', 'success');
							}
							self.hideTrackerControls();
						} else {
							$('.timeclock').alert(response.message, 'error');
						}
					},
					dataType: 'json'
				});
			});

			$('.timeclock button.cancel-tracker').on('click', function(e) {
				self.hideTrackerControls(true);
			});
		},

		hideTrackerControls: function(immediate) {
			// var self = this;
			// $('#timeClock').modal('hide');
			var delay = immediate ? 0 : 2000;
			setTimeout(function(){
				$('#timeClock').modal('hide');
			}, delay);
		},

		makeStopButton: function()
		{
			var self = this;
			//$(self.button).html('Stop');
		//	$(self.button).removeClass('btn-success');
		//	$(self.button).addClass('btn-danger');
		},

		makeStartButton: function()
		{
			var self = this;
			//$(self.button).html('Start');
		//	$(self.button).addClass('btn-success');
		//	$(self.button).removeClass('btn-danger');
		},

		resetTrackerForm: function() {
			var $controls = $('.timeclock');
			$controls.find('.confirmation, .stat, .pin').hide();
			$controls.find('.stat .status-label').removeClass('stopped, started');
			$controls.find('.stat button.btn-success').val('');
			$controls.find('.pin .help-inline').remove();
			$controls.find('.pin form').find('input[type="password"]').val('');
			$controls.find('.confirmation span').html('');
			$controls.find('.alert.alert-error').remove();
		}
	}

	$.registry('clockWidget', clockWidget, {
		methods: ['init']
	});

}( jQuery ));