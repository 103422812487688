$.app = {
	baseUrl: '',
	page: undefined,

	init: function() {
		if ($.app.page !== undefined) {
			if (!$.app.page.delayed)
				$.app.page.init.call($.app.page);
		}
		//force body release scroll
		this.modalBehaviors();
		this.inputSearchBehaviors();
		this.globalFunctions();
	},
	utils: {
		insertSpaces: function(string) {
			string = string.replace(/([a-z])([A-Z])/g, '$1 $2');
			string = string.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
			return string;
		},
		escapeSelector: function (selector) {
			return selector.replace(/(:|\.|\[|\]|,|=|@)/g, "\\$1");
		},
		removeCSRFTokenFromArray: function (arrayData, keyValue = null, singleOccurrence = true) {
			if (!Array.isArray(arrayData)) {
				return arrayData;
			}
			let csrfTokenName = $.app.page.options.csrfTokenName ?? "YII_CSRF_TOKEN"; // Yii::app()->request->csrfTokenName | app/protected/views/catalogItem/edit.php

			for (let k in arrayData) {
				let content = arrayData[k];
				let validateCurrentKey = k === csrfTokenName;
				let validateKeyValues = !!content[keyValue] && content[keyValue] === csrfTokenName;

				if (validateCurrentKey || validateKeyValues) {

					arrayData.splice(k,1);
					if (singleOccurrence) {
						break;
					}
				}
			}
			return arrayData;
		},
	},
	modalBehaviors: function() {
		$(document).on('shown', '.modal', function() {
			$('body').css("overflow", "hidden");
			$('.modal').css("overflow", "overlay");
		})
		.on('hidden', '.modal', function() {
			$('body').css("overflow", "auto");
			// remove any backdrop in case there are no modals visible
			// prevents black screen of death after modal
			if (!$('.modal:visible').length) {
				$('.modal-backdrop').remove();
			}
		})
	},
	inputSearchBehaviors: function() {
		$( document ).ready(function() {
			var last_key = 0;
			var $input = $('.ui-autocomplete-input');
			$input.on("keydown", function(e){
				// SRQS-847 Removed to restore arrow key functionality in search results
				// if( (last_key !== 17 && e.which !== 17) && (e.which !== 16) ){
				// 	$(this).autocomplete("close");
				// }

				last_key = e.which;
			});

			$input.on("keyup", _.debounce(function(e){
				last_key = 0;
			}, 300));
		});
	},
	globalFunctions: function(){
		/**
		 * 
		 * @param {type} int | ticketEnabledTicketType
		 * @param {type} int | allowedCatalogCreateContext
		 * @returns {Element} | It's either Lookup + Create or Create button only
		 */
		window.enabledCreateButton = function(ticketType, name, enabledTicketType, allowedCatalogCreateContext, hasLookup = false, menu = true){

			function displayButton( ticketType, name, hasLookup, buttonType, enabledTicketType, singleColumn ) {
				let button = '';
				let quickAddCatalogCreateNewEvent = '';
			
				const columnClass = !singleColumn ? 'col-sm-6' : 'col-sm-12';

				// Note: This conditions set the click event of the Create New Button
				if ( ticketType == 'ticket' ) {
					quickAddCatalogCreateNewEvent = `onclick="javascript:$.app.showQuickAddCatalog('ticket_item_sale', '`+name+`')"`;
				} else if ( ticketType == 'purchase_order' ) {
					quickAddCatalogCreateNewEvent = `onclick="javascript:$.app.showQuickAddCatalog('purchase_order_item', '`+name+`')"`;
				} else if ( ticketType == 'device' ) {
					quickAddCatalogCreateNewEvent = `onclick="javascript:$.app.showQuickAddCatalog('ticket_device', '`+name+`')"`;
				} else if ( ticketType == 'tradein' ) {
					quickAddCatalogCreateNewEvent = `onclick="javascript:$.app.showQuickAddCatalog('trade_in_ticket', '`+name+`')"`;
				}

				switch (buttonType) {
					case 'create_new':
						if (enabledTicketType == 0) {
								button = '<div class="'+columnClass+'">'+
										`<button class="btn-link" data-toggle="tooltip" id="cqcCreateNewDisabled" data-placement="right" onmouseover="javascript:$.app.listHoverFunc()" data-original-title="Enable <span style='text-transform: lowercase;'>this feature in </span> Company Settings. <a href='https://servicecentral.atlassian.net/servicedesk/customer/portals?src=repairq_webapp/' target='_blank' style='text-decoration:underline;color:white;'>Learn <span style='text-transform: lowercase;'>more here</span></a>" style="color:#27a9e175 ;cursor:default;text-decoration: underline; white-space: nowrap;"><i class="icon-plus-sign"></i> Create New</button>`+
										'</div>';
						} else { // Note: This will be used when enabled ticket set to true, and this variable "quickAddCatalogCreateNewEvent" will be included for click event
							button = '<div class="'+columnClass+'">'+
										'<button class="btn-link " style="text-decoration: underline; white-space: nowrap;" '+quickAddCatalogCreateNewEvent+'><i class="icon-plus-sign"></i> Create New</button>'+
									'</div>'
						}
						break;
					case 'look_up':
						if ( hasLookup ) {
							if ( ticketType == 'ticket' ) {
								button = '<div class="'+columnClass+'">'+
											'<button type="button" style="text-decoration: underline;" onclick="javascript:$.app.page.tickets.create()" data-toggle="modal" data-target="#ticket-item-form" class="btn-link">' +
												'<i class="icon-search"></i> Look up' +
											'</button>' +
										'</div>'
							} else if(ticketType == 'tradein') {
								button = '<div class="'+columnClass+'">'+
									'<a href="#" style="text-decoration: underline; white-space: nowrap;" onclick="javascript:$.app.page.tradeinItems.create()" class="add-trade-in btn-link" data-toggle="modal" data-target="#new-trade-in-form" style="margin: 0 10px 0 0;">' +
										'<i class="icon-search"></i> Look up' +
									'</a>' +
								'</div>'
							}else {
								button = '<div class="'+columnClass+'">'+
											'<button type="button" style="text-decoration: underline;" onclick="javascript:$.app.page.devices.create()" data-toggle="modal" data-target="#new-device-form" class="btn-link">' +
												'<i class="icon-search"></i> Look up' +
											'</button>' +
										'</div>'
							}
						}
						
						break;
					default:
						break;
				}
			
				return button;
			}

			let enabledCreate = {
				'displayName': '<div style="text-align: center; padding: 15px 4px; border-top: 1px solid #ccc;"> '+ 
								displayButton( ticketType, name, hasLookup, 'look_up', enabledTicketType, false ) + 
								displayButton( ticketType, name, hasLookup, 'create_new', enabledTicketType, false ) +
							'</div>',
				'footer': true
			}

			if (ticketType == 'purchase_order') {
				enabledCreate = {
					'displayName': '<div style="text-align: center; padding: 15px 4px; border-top: 1px solid #ccc;"> '+ 
									displayButton( ticketType, name, false, 'create_new', enabledTicketType, true ) + 
								'</div>',
					'footer': true
				}
			}
			
			if(!allowedCatalogCreateContext){
				if (ticketType == 'ticket' || ticketType == 'device' || ticketType == 'tradein') {
					enabledCreate = {
						'displayName': '<div style="text-align: center; padding: 15px 4px; border-top: 1px solid #ccc;"> '+ 
										displayButton( ticketType, name, hasLookup, 'look_up', enabledTicketType, true ) + 
									'</div>',
						'footer': true
					}
				} else if (ticketType == 'purchase_order') {
					enabledCreate = {
						'displayName': '<div style="text-align: center; padding: 15px 4px; border-top: 1px solid #ccc;"> '+ 
										displayButton( ticketType, name, false, 'create_new', enabledTicketType, true ) + 
									'</div>',
						'footer': true
					}
				} else {
					enabledCreate = '';
				}
			}
			if(menu) return enabledCreate;
			return 0;
			
		}
		window.fieldErrorMessage = function(id="", message="", style="", additional="") {
			return `<p class="error" style="font-size: 12px; color: red; ${style}" id="${id}">${message}${additional}</p>`;
		}

		window.fieldSetter = function(fieldId, containerClass = '', resetField = false, fieldName = '', message = '') {

			if(!resetField) {
				$(`#${fieldId}`).css({ 'border-color': '', "box-shadow": "", "margin-bottom": "10px" });
				$(`#${fieldId}`).css({ "border-color": "red", "box-shadow": "none", "color": "rgb(255, 0, 0)", "margin-bottom": "0px" });
				$(`#${fieldId}`).closest('.control-group').find('label').css({ 'color': 'red' });
				if(containerClass != '') {
					$(containerClass).addClass('with-error');
				}

				if ($(`#${fieldId}_error`).length == 0) {
					$(`#${fieldId}`).after(`<p class="error" style="font-size: 12px; color: red;" id="${fieldId}_error">${message}</p>`);      
				}

				if (!$(`#${fieldId}`).next().hasClass('error')) {
					$(`#${fieldId}`).after(`<p class="error" style="font-size: 12px; color: red;" id="${fieldId}_error">${message}</p>`);
				}
			} else {
				if(containerClass != '') {
					$(containerClass).removeClass('with-error');
				}
				$(`#${fieldId}_error`).remove();
				$(`#${fieldId}`).css({ "margin-bottom": "10px", "color": "" });
				$(`#${fieldId}`).css({ 'border-color': '', "box-shadow": "" });
				$(`#${fieldId}`).closest('.control-group').find('label').css({ 'color': '' });
			}
		}
		window.disableCQCButton = function(buttonSelector) {
			$(buttonSelector).attr('title',"Enable <span style='text-transform: lowercase;'>this feature in </span>Company Settings. <a href='https://servicecentral.atlassian.net/servicedesk/customer/portals?src=repairq_webapp/' target='_blank' style='text-decoration:underline;color:white;'>Learn <span style='text-transform: lowercase;'>more here</span></a>")
			$(buttonSelector).removeClass('quick-add-catalog')
			$(buttonSelector).attr('data-toggle','tooltip')
			$(buttonSelector).attr('data-placement','left')
			$(buttonSelector).css( 'cursor', 'default' )
			$(buttonSelector).css("color", "#27a9e175") 
			$(buttonSelector).on('mouseover', function() {
				$(this).css("background", "none") 
			});
			$(buttonSelector).css("data-delay", '{"show":"0", "hide":"500"}')		
			$(buttonSelector+'[data-toggle="tooltip"]').tooltip({
				trigger: "manual",
				html: true
			});
			let hovered = 0;
			let timeVar = {};
			$(buttonSelector+'[data-toggle="tooltip"]').on('mouseleave', function () {
				hovered = 0;
				timeVar.tooltipTimeout = setTimeout(() => {
					$(buttonSelector+'[data-toggle="tooltip"]').tooltip('hide');
				}, 500);
			});
			$(buttonSelector+'[data-toggle="tooltip"]').on('mouseenter', function () {
				clearTimeout(timeVar.tooltipTimeout);
				if (hovered == 0) {
					$(buttonSelector+'[data-toggle="tooltip"]').tooltip('show');
				}
				hovered = 1;
				$('.tooltip').on('mouseenter', function () {
					hovered = 1;
					clearTimeout(timeVar.tooltipTimeout);
					$('.tooltip').on('mouseleave', function () {
					clearTimeout(timeVar.tooltipTimeout);
						timeVar.tooltipTimeout = setTimeout(() => {
							$(buttonSelector+'[data-toggle="tooltip"]').tooltip('hide');
							hovered = 0;
						}, 500);
					});
				});
			});

			$(buttonSelector).on('click',function(e){
				e.preventDefault();
			})
		};

		window.checkForDuplicate = function(attribute, value) {
			if (value.length >= 3) {
				Q.promise(function(resolve, reject){
					Q($.ajax({
						url: '/ajax/catalogItem/checkIfItemExists',
						type: 'POST',
						dataType: 'json',
						data: {
							data: value,
							attribute: attribute
						}
					}).then(function (response) {
						if (response.success) {
							$(`#CatalogItem_${response.attribute}_error`).remove()
							$(`#CatalogItem_${response.attribute}`).removeAttr("style");
							if ($(`#CatalogItem_name_error`).length == 0 && $(`#CatalogItem_sku_error`).length == 0) {
								$('#quick-catalog-createitem').prop('disabled', false);
								$('#quick-catalog-createitem').removeAttr("style");
							}
							if (response.exists) {
								$(`#CatalogItem_${response.attribute}`).after(`
									<p class="error text-danger" style="font-size: 12px" id="CatalogItem_${response.attribute}_error">
										${response.attribute == 'sku' ? 'SKU' : 'Name'} is already in use. 
										<u>
											<a href="#" style="cursor:pointer" onclick="openExisting('${encodeURIComponent(JSON.stringify(response.exists))}')" class="text-danger">Use existing item</a>
										</u>
									</p>
								`)
								$(`#CatalogItem_${response.attribute}`).css({"border-color":"#C00", "box-shadow":"none"});
								$('#quick-catalog-createitem').prop('disabled', true).attr('style', "background-color: #0091CD !important; border-color: #0091CD !important; opacity: 0.5 !important; color: #FFFFFF !important");
							}
						}
					}).always(function () {
						$(".loading-modal").hide();
						$('.quick-add-po').val('');
					}));
				}).catch(function(error) {
					console.warn('err', error);
				});
			} else {
				$(`#CatalogItem_${attribute}_error`).remove()
				$(`#CatalogItem_${attribute}`).removeAttr("style");
				$(`#CatalogItem_${attribute}`).remove('has-error');
				if ($(`#CatalogItem_name_error`).length == 0 && $(`#CatalogItem_sku_error`).length == 0) {
					$('#quick-catalog-createitem').prop('disabled', false);
					$('#quick-catalog-createitem').removeAttr("style");
				}
				$(".loading-modal").hide();
				$('.quick-add-po').val('');
			}
		}

		window.setBehaviors = function(isInventoried) {
			if (!isInventoried) {
				// Disable 'is serialized' radio input
				$('.div-default_qty').find('.control-label').css('display', 'block');
				$('.div-default_qty').find('.input-block').css('display', 'block');
				$('.div-default_qty').css('margin-bottom', '20px');
				$('.div-advertised').css('margin-top', '0px');
				$('.div-active').css('margin-top', '0px');
	
				$('.div-restocking_fee').css('display', 'none');
				$('.div-restocking_fee').find('.control-label').css('display', 'none');
				$('.div-restocking_fee').find('.input-block').css('display', 'none');
	
				$('.div-discount').css('display', 'none');
				$('.div-discount').find('.control-label').css('display', 'none');
				$('.div-discount').find('.input-block').css('display', 'none');
	
				$('.div-reorder_qty').css('display', 'none');
				$('.div-reorder_qty').find('.control-label').css('display', 'none');
				$('.div-reorder_qty').find('.input-block').css('display', 'none');
	
				$('.div-reorder_amt').css('display', 'none');
				$('.div-reorder_amt').find('.control-label').css('display', 'none');
				$('.div-reorder_amt').find('.input-block').css('display', 'none');
	
				$('#ytis_serialized_input').parent().parent().css({ "opacity": "0.5" });
				$('#CatalogItem_is_serialized_0').prop('disabled', true);
				$('#CatalogItem_is_serialized_1').prop('disabled', true);
				$('#CatalogItem_is_serialized_1').prop('checked', true);
				$('#ytis_serialized_input').val('0');
				// Disable 'have items to stock' radio input
				$('#ythave_items_to_stock_input').parent().parent().css({ "opacity": "0.5" });
				$('#CatalogItem_have_items_to_stock_0').prop('disabled', true);
				$('#CatalogItem_have_items_to_stock_1').prop('disabled', true);
				$('#CatalogItem_have_items_to_stock_1').prop('checked', true);
				$('#ythave_items_to_stock_input').val('0');
				if ($("#CatalogItem_context").val() != 'ticket_device') {
					$('#CatalogItem_is_repairable_0').prop('checked', true);
				}
			} else {
				$('.div-default_qty').find('.control-label').css('display', 'none');
				$('.div-default_qty').find('.input-block').css('display', 'none');
				$('.div-default_qty').css('margin-bottom', '20px');
				$('.div-advertised').attr('style', "margin-top: -40px; width: 32%");
				$('.div-active').attr('style', "margin-top: -40px; width: 32%");
	
				$('.div-restocking_fee').css('display', 'block');
				$('.div-restocking_fee').find('.control-label').css('display', 'block');
				$('.div-restocking_fee').find('.input-block').css('display', 'block');
				$('.div-restocking_fee').find('.control-group').css({"padding": "0", "border": "none", "margin-left": "-2.5%","margin-bottom": "30px !important", "display": "block"});
	
				$('.div-discount').css('display', 'block');
				$('.div-discount').find('.control-label').css('display', 'block');
				$('.div-discount').find('.input-block').css('display', 'block');
	
				$('.div-reorder_qty').css('display', 'block');
				$('.div-reorder_qty').find('.control-label').css('display', 'block');
				$('.div-reorder_qty').find('.input-block').css('display', 'block');
	
				$('.div-reorder_amt').css('display', 'block');
				$('.div-reorder_amt').find('.control-label').css('display', 'block');
				$('.div-reorder_amt').find('.input-block').css('display', 'block');
	
				$('#ytis_serialized_input').parent().parent().removeAttr("style");
				$('#CatalogItem_is_serialized_0').prop('disabled', false);
				$('#CatalogItem_is_serialized_1').prop('disabled', false);
				$('#CatalogItem_is_serialized_1').prop('checked', true);
				// Enable 'have items to stock' radio input
				$('#ythave_items_to_stock_input').parent().parent().removeAttr("style");
				$('#CatalogItem_have_items_to_stock_0').prop('disabled', false);
				$('#CatalogItem_have_items_to_stock_1').prop('disabled', false);
				$('#CatalogItem_have_items_to_stock_1').prop('checked', true);
				if ($("#CatalogItem_context").val() != 'ticket_device' && $("#CatalogItem_context").val() != 'trade_in_ticket') {
					$('#CatalogItem_is_repairable_1').prop('checked', true);
				}
			}
	
		};
	},
	/**
	 * @listHoverFunc: for the "Create New" button that gets shown on item suggest results, used on the ticket edit and purchase order edit screens. 
	 * This is only called when the cqc settings are disabled.
	*/ 
	listHoverFunc: function(){
		var self = this;
		if(typeof self.cqcHoverTimerObj === "undefined"){
			self.cqcHoverTimerObj = {tooltipTimeout:null};
			self.enteredHover = 0;
		}
		$('#cqcCreateNewDisabled[data-toggle="tooltip"]').tooltip({
			trigger: "manual"
		});
		
		clearTimeout(self.cqcHoverTimerObj.tooltipTimeout);
		if(self.enteredHover == 0) $('#cqcCreateNewDisabled[data-toggle="tooltip"]').tooltip('show');
		
		//the .tooltip filter used on purchase orders where multiple instances of this button are shown
		$('.tooltip').filter(function() {
			if($(this)[0].style.left === "0px"){
				return true
			}
		}).hide()

		$('#cqcCreateNewDisabled[data-toggle="tooltip"]').on('mouseleave', function () {
			self.enteredHover = 0
			clearTimeout(self.cqcHoverTimerObj.tooltipTimeout);
			self.cqcHoverTimerObj.tooltipTimeout = setTimeout(() => {
				$('#cqcCreateNewDisabled[data-toggle="tooltip"]').tooltip('hide');
			}, 500);
		});
		$('.tooltip').on('mouseenter', function () {
			self.enteredHover = 1
			clearTimeout(self.cqcHoverTimerObj.tooltipTimeout);
			$('.tooltip').on('mouseleave', function () {
			clearTimeout(self.cqcHoverTimerObj.tooltipTimeout);
				self.cqcHoverTimerObj.tooltipTimeout = setTimeout(() => {
					$('#cqcCreateNewDisabled[data-toggle="tooltip"]').tooltip('hide');
					self.enteredHover = 0
				}, 500);
			});
		});
	},
	/**
	 * @showQuickAddCatalog: this loads the Catalog Quick Create screen and populates the name field
	 */
	showQuickAddCatalog: function(context, name){
		function radioFieldDisplay(fieldName, isDisabled, yesNo){
			if (isDisabled) {
				$('#yt' + fieldName + '_input').parent().parent().css({"opacity":"0.5"})
				$('#CatalogItem_' + fieldName + '_0').prop('disabled', true);
				$('#CatalogItem_' + fieldName + '_1').prop('disabled', true);
			} else {
				$('#yt' + fieldName + '_input').parent().parent().removeAttr("style")
				$('#CatalogItem_' + fieldName + '_0').prop('disabled', false);
				$('#CatalogItem_' + fieldName + '_1').prop('disabled', false);
			}
			//add selection
			switch (yesNo) {
				case 'yes':
					$('#CatalogItem_' + fieldName + '_0').prop('checked', true);
					if(isDisabled) {
						$('#yt' + fieldName + '_input').val('1');
					}
					break;
				case 'no':
				default:
					$('#CatalogItem_' + fieldName + '_1').prop('checked', true);
					if(isDisabled) {
						$('#yt' + fieldName + '_input').val('0');
					}
					break;
			}
		}

		function handleDisplayAdjustments(context) {
			switch (context) {
				case 'ticket_device':
					setBehaviors(false); //defaults to not inventoried.
					radioFieldDisplay('is_serialized', true, 'no');
					radioFieldDisplay('have_items_to_stock', true, 'no');
					radioFieldDisplay('is_repairable', true, 'yes');
					$('#CatalogItem_price').val(0).trigger('change'); // set default to zero for repair device
					break;
				case 'purchase_order_item':
					setBehaviors(true);
					radioFieldDisplay('is_inventoried', true, 'yes');
					radioFieldDisplay('is_serialized', false, 'no');
					radioFieldDisplay('have_items_to_stock', false, 'no');
					radioFieldDisplay('is_repairable', false, 'no');
					break;
				case 'trade_in_ticket':
					setBehaviors(true);
					radioFieldDisplay('is_inventoried', true, 'yes');
					radioFieldDisplay('is_serialized', false, 'no');
					radioFieldDisplay('have_items_to_stock', false, 'yes');
					radioFieldDisplay('is_repairable', ($.app.page.options.trade_repairable_only ? true : false), 'yes');
					break;
				case 'ticket_item_sale':
					setBehaviors(false); //defaults to not inventoried.
					radioFieldDisplay('is_serialized', true, '');
					radioFieldDisplay('have_items_to_stock', true, '');
					radioFieldDisplay('is_repairable', false, '');
					
					break;
			}
		}

		if (name === null || typeof name == 'undefined') {
			name = '';
		}
		
		var allowedItems = [];
		var defaultType = null;
		//determine
		switch (context) {
			case 'ticket_device':
				allowedItems = $.app.page.options.repairAllowedTicketTypes;
				defaultType = $.app.page.options.repairDefaultTicketType;
				break;
			case 'purchase_order_item':
				allowedItems = $.app.page.options.purchaseOrderAllowedTicketTypes;
				defaultType = $.app.page.options.purchaseOrderDefaultTicketType; 
				break;
			case 'trade_in_ticket':
				allowedItems = $.app.page.options.tradeInAllowedTicketTypes;
				defaultType = $.app.page.options.tradeInDefaultTicketType; 
				break;
			case 'ticket_item_sale':
			default:
				context = 'ticket_item_sale';
				allowedItems = $.app.page.options.ticketAllowedTicketTypes;
				defaultType = $.app.page.options.ticketDefaultTicketType; 
				break;
		}

		if ($('#quick-catalog-form').length) {
			$('#quick-catalog-form').remove()
		}

		Q.promise(function (resolve, reject) {
			Q($.ajax({
				url: '/ajax/catalogItem/loadQuickCatalogModal',
				dataType: 'json',
				data: {
					name: name,
					context: context
				}
			}).then(function (response) {
				if (response.success && response.html) {
					$('body').append(response.html);
					const end = name.length;
					$('#CatalogItem_name')[0].setSelectionRange(end, end);
					var existingDetails = document.getElementById("tab-general-information").children[1]
					existingDetails.style.display = "none";
					$('#quick-catalog-form').modal({
						backdrop: 'static',
						keyboard: false
					}, 'show');//.modal('show');
					// limit item types from settings for ticketItem
					$('#CatalogItem_item_type_id option:not(:first)').filter(function() {
						return true;
					}).show();
					
					checkForDuplicate('name', name);
					
					if(allowedItems.length !=0) {
						$('#CatalogItem_item_type_id option:not(:first)').filter(function() {
							return $.inArray($(this).val().trim(), allowedItems) == -1;
						}).remove();
					}
					$('#CatalogItem_item_type_id').val(defaultType)
				}
			}).always(function () {
				$(".loading-modal").hide();

				handleDisplayAdjustments(context);

				// Note: After the modal load, initialized the event for the input price
				//globalInitializeInputPriceEvent();
			}));
		}).catch(function (error) {
			console.warn('err', error);
		});
	}
};
