(function($) {

	$.fn.serializePart = function() {
		var plainValues = this
			.find('input,textarea,select,button')
			.serializeArray();
		var structuredValues = {};
		if (this.length === 0) {
			return structuredValues;
		}
		for(var index in plainValues) {
			if(typeof(plainValues[index]) === 'undefined') {
				continue;
			}
			if (typeof plainValues[index].name === 'undefined') {
				continue;
			}
			var value = plainValues[index].value;
			var namePath = plainValues[index].name.replace(/\]/g,'').split('[');
			var start = structuredValues;
			var key;
			for(var i = 0; i < namePath.length; i++) {
				key = namePath[i];
				if (i != namePath.length - 1) {
					if (typeof(start[key]) === 'undefined')
						start[key] = namePath[i+1] !== ''
							? {}
							: [];
				} else if (key === '') {
					start.push(value);
					continue;
				} else {
					start[key] = value;
				}
				start = start[key];
			}
		}
		return structuredValues;
	};

	$.fn.serializePartWithMultiselect = function() {
		var plainValues = this
			.find('input,textarea,select,button')
			.serializeArray();
		var structuredValues = {};
		for(var index in plainValues) {
			if(typeof(plainValues[index]) === 'undefined') {
				continue;
			}
			var value = plainValues[index].value;
			var namePath = plainValues[index].name.replace(/\]/g,'').split('[');
			var start = structuredValues;
			for(var i = 0; i < namePath.length; i++) {
				if (i != namePath.length - 1) {
					if (typeof(start[namePath[i]]) === 'undefined')
						start[namePath[i]] = {};
				} else {
					if(typeof start[namePath[i]] != 'undefined' && $('select[name="'+namePath[0]+'[]"]').length > 0){ //if all multiselect boxes are named with [], this will work. only used in app_settings for now.
						if(!Array.isArray(start[namePath[i]])){
							var current = start[namePath[i]];
							start[namePath[i]]=[];
							start[namePath[i]].push(current);
							start[namePath[i]].push(value);
						}else{
							start[namePath[i]].push(value);	
						}
						// start[namePath[i]] = value;
					}
					else{
						start[namePath[i]] = value;	
					}
					
				}
				start = start[namePath[i]];
			}
		}
		return structuredValues;
	};

})(jQuery);