(function ($) {
	var switchUserWidget = function (element, options) {
		var self = this;
		self.options = options;
		self.element = element;
	};

	switchUserWidget.prototype = {
		init: function () {
			var self = this;

			var workstationKey = localStorage.getItem('terminal_id') == null ? cookieStorage.getItem('terminal_id') : localStorage.getItem('terminal_id');
			if (workstationKey == null) {
				workstationKey = self.randomString();
				localStorage.setItem('terminal_id', workstationKey);
				cookieStorage.setItem('terminal_id', workstationKey);
			} else {
				localStorage.setItem('terminal_id', workstationKey);
			}
			$('.switch-pin').after('<input type="hidden" name="UserLoginForm[workstation_key]" value="'+workstationKey+'"/>');
			self.bindActions();

		},

		bindActions: function () {
			var self = this;

			$('.switch-user-link').on('click', function(e) {
				e.preventDefault();
				$('.tracker-controls').hide();
				self.clearForm();
				// self.element.slideToggle('fast', function() {
				// 	self.element.find('[type="password"]').trigger('focus');
				// 	self.clearForm();
				// });
			});
			// $('.terminal').bind('hover', function(e) {
			// 	$('.switch-user').hide();
			// 	$('.tracker-controls').hide();
			// });

			self.element.find('form#switch-user').on('submit', function(e) {
				e.preventDefault();
				var that = this;
				self.element.find('.help-inline.error').remove();
				$.ajax({
					url: '/ajax/user/switch',
					data: $(that).serializeArray(),
					success: function (response) {
						if (response.success) {
							window.location.href = response.returnUrl;
						} else {
							if(response?.message?.[0]?.errorCode=='error_dup_pin') self.element.find('.switch-username.hide').removeClass('hide');
							self.element.prepend('<span class="help-inline error alert alert-error">'+(response?.message?.[0]?.password?.[0] ?? response?.message)+'</span>');
						}
					},
					dataType: 'json',
					type: 'POST'
				});
			});
		},

		clearForm: function () {
			var self = this;
			self.element.find('.help-inline.error').remove();
			self.element.find('form input[type="password"]').val('');
		},

		randomString: function () {
			var chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
			var string_length = 16;
			var randomstring = '';
			for (var i=0; i < string_length; i++) {
				var rnum = Math.floor(Math.random() * chars.length);
				randomstring += chars.substring(rnum, rnum + 1);
			}
			return randomstring;
		}
	}

	$.registry('switchUserWidget', switchUserWidget, {
		methods: ['init']
	});


}( jQuery ));