// Loading CSS
import '../css/bootstrap-treeview.min.css';
// import('../less/app.less'); // the bootstrap.less code should be upgrade it
import '../css/tour.css';
import '../css/custom.css';
import '../node_modules/font-awesome/css/font-awesome.css';
import '../css/tooltips.css';
// import('../css/print.css'); // why not working?
import '../css/bootstrap-print.min.css';
import '../css/jquery-ui-bootstrap.css';
import '../node_modules/select2/dist/css/select2.min.css';

/**
 * script-loader! => import file as a script tag <script src="path_to_file.js"></script>
 */

// Loading JS libs
import {debounce, each, chain, clone, escape, intersection,
	sortBy, range, isEmpty, uniq, union, some, pluck, difference,
	has, reject, map, find, filter, without, contains, findIndex
} from 'underscore';
global._ = {};
global._.debounce = debounce;
global._.each = each;
global._.chain = chain;
global._.clone = clone;
global._.escape = escape;
global._.filter = filter;
global._.findIndex = findIndex;
global._.intersection = intersection;
global._.sortBy = sortBy;
global._.range = range;
global._.isEmpty = isEmpty;
global._.uniq = uniq;
global._.union = union;
global._.some = some;
global._.pluck = pluck;
global._.difference = difference;
global._.has = has;
global._.reject = reject;
global._.map = map;
global._.find = find;
global._.without = without;
global._.contains = contains;

import Swal from 'sweetalert2';
import Q from 'q';
import $ from 'jquery';
import 'script-loader!../node_modules/jquery-ui/dist/jquery-ui.js';
import './ext/bootstrap.js';
import 'script-loader!../node_modules/systemjs/dist/system.js';
import 'script-loader!../node_modules/ejs/ejs.js';
global.Swal = Swal;
global.Q = Q;

if (process.env.IS_DEV === false || process.env.IS_DEV == 0) {
	require('script-loader!../node_modules/jquery-migrate/dist/jquery-migrate.js');
}

import './app/patches.js';
import './app/app.js';
import './ext/jquery-plugins/jquery.registry.js';
import './app/wysiwyg.js';
import 'script-loader!../node_modules/select2/dist/js/select2.min.js';
import 'script-loader!./app/select2-adapter.js';
import 'script-loader!./app/plugins/rqDatepicker.js';
import 'script-loader!./app/bootstrapping.js';
import './app/hooks/local-storage.js';
import './ext/extend.js'; // necessary for brick to work
import 'script-loader!./app/brick.js';
import './app/plugins/jquery.multiplyforms.js';
import '../js/app/plugins/jquery.suggest.js';
import './ext/jquery.validate.js';
import './app/jquery.validate.pattern.js';
import './app/plugins/jquery.template.js';
import './app/plugins/jquery.serializePart.js';
import './app/widgets/clock.widget.js';
import './app/widgets/switch-user.widget.js';
import './app/plugins/jquery.session-timeout.js';
import './ext/jquery-plugins/jquery-barcode-2.2.0.min.js';
import './app/plugins/jquery.zindex-filter.js';
import './ext/bootstrap-datetimepicker.min.js';
import './ext/bootstrap-treeview.min.js';
import 'script-loader!../node_modules/bootstrap-multiselect/dist/js/bootstrap-multiselect.js';
import './app/mousetrap.js';
import './app/shim.js';
import 'script-loader!./app/NetworkConfig.js';
import 'script-loader!./app/WebSocketInterface.js';
import 'script-loader!./app/csrfPrefilter.js';

// ejs custom file loader
let myFileLoader = function (filePath) {
	return getFileContent(filePath);
};
ejs.fileLoader = myFileLoader;

System.config({
	baseUrl: `${baseUrl}/js`,
	map: {
		"bootstrap-timepicker": "/js/ext/bootstrap-timepicker/js/bootstrap-timepicker.js",
		css: "/node_modules/systemjs-plugin-css/css.js", // plugin to load css as a dependency
		dropzone: "/node_modules/dropzone/dist/min/dropzone.min.js",
		fullcalendar: "/js/ext/fullcalendar/fullcalendar.js",
		fullcalendarLocale: fullCalendarLocale || null,
		moment: "/node_modules/moment/min/moment.min.js",
		NetworkConfig: "/js/app/NetworkConfig.js",
		WebSocketInterface: "/js/app/WebSocketInterface.js"
	},
	meta: {
		"*.css": {loader: "css"},
		"/js/app/pos-companion.js": {
			deps: ['NetworkConfig']
		},
		"bootstrap-timepicker": {
			deps: [
				"/js/ext/bootstrap-timepicker/css/bootstrap-timepicker.min.css",
			]
		},
		dropzone: {
			deps: [
				"/node_modules/dropzone/dist/min/dropzone.min.css",
			],
			format: "global",
		},
		fullcalendar: {
			deps: [
				// not sure why, but moment.js needs placed under "globals"
				"/js/ext/fullcalendar/fullcalendar.min.css",
				// "fullcalendarLocale"
			],
			format: "global",
			globals: {
				moment: "moment"
			}
		},
		fullcalendarLocale: {
			format: "global"
		},
		moment: {format: "global"},
		NetworkConfig: {
			deps: ['WebSocketInterface']
		}
	}
});
