(function($) {

	var Template = function(element, options) {
		var self = this;
		self.element = element;
		self.options = options;
	};

	Template.prototype = {
		element: undefined,
		options: undefined,
		defaultOptions: undefined,
		template: null,

		init: function() {
			var self = this;
			self.template = ejs.compile(this.element.get(0).innerHTML);

			$.fn.render = function(options, target) {
				var template = this.data('template');
				// allow dynamic/nested templates by changing target on the fly
				if(target) {
					template.options.target = target;
				}
				if (template.options.target.length === 0) {
					// target didn't exist at time template was "compiled".. try again
					template.options.target =  $(template.options.target.getSelector(true));
				} else if (!jQuery.contains(document, template.options.target[0])) {
					// target detached from DOM?
					template.options.target = $(template.options.target.getSelector(true));
				}
				// check if target is set
				// add console warn to help debug future issues
				if (!template.options.target.length) {
					console.warn('No target specified for template', template.options);
				}
				this.data('template').renderTo(template.options.target, options);
			};

			$.fn.renderTo = function(targetElement, options) {
				this.data('template').renderTo(targetElement, options);
			};
		},

		renderTo: function(targetElement, options) {
			var self = this;
			var data = $.extend(true, options, self.options.helpers);
			var template = self.template(data, self.options.helpers);
			$(targetElement).each(function(index, element){
				element.innerHTML = template;
				$(element).trigger('rendered')
			});
		},

		_: ''
	};

	$.registry('template', Template, {
		methods: ['init', 'renderTo', 'render'],
		defaults: {
			target: null
		}
	});
})(jQuery);