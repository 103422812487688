import Mousetrap from 'mousetrap';
// keyboard shortcut bindings
$(document).ready(function() {
	if(typeof Mousetrap !== 'undefined') {
		//Global
		// Binding for 'q t' shortcut
		Mousetrap.bind('q t', function(e) {
			$('.qt-search:input:visible').first().trigger('focus');
			return false;
		});

		// Binding for 'q i' shortcut
		Mousetrap.bind('q i', function(e) {
			$('.qi-search:input:visible').first().trigger('focus');
			return false;
		});

		// Binding for 'q c' shortcut
		Mousetrap.bind('q c', function(e) {
			$('.qc-search:input:visible').first().trigger('focus');
			return false;
		});

		// Tickets Binding
		Mousetrap.bind('ctrl+shift+i', function(e) {
			if ($.app && $.app.page && $.app.page.tickets && $.app.page.tickets.enabled) {
				$('.add-ticket').first().trigger('click');
			} else {
				return true;
			}
		});

		// Payments Binding
		Mousetrap.bind('ctrl+shift+z', function(e) {
			if ($.app && $.app.page && $.app.page.payments && $.app.page.payments.enabled) {
				$('.add-transaction').first().trigger('click');
			} else {
				return true;
			}
		});

		// use backtick to set focus to first/topmost visible scan field
		Mousetrap.bind('`', function(e) {
			$('.scan:input:visible').filterMaxZIndex().first().trigger('focus');
			return false;
		});

		// use enter key (only while focused on a scan field)
		// to automatically click the button next to it
		Mousetrap.bind('enter', function(e) {
			var $scan = $('.scan:focus');
			if($scan.length) {
				var $goButton = $scan.parent().find('button:first');
				if($goButton.length) {
					$goButton.trigger('click');
					$scan.val('');
					$scan.first().trigger('focus');
				}
			} else {
				return true;
			}
		});
	}
	else {
		throw new Error("Mousetrap.js not loaded!");
	}
});
