import tinymce from 'tinymce';
import 'tinymce/themes/silver';
import 'tinymce/icons/default';

/* Import plugins */
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/image';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/code';

/* Import content css */
import 'tinymce/skins/ui/oxide/skin.css';
import 'tinymce/skins/ui/oxide/content.css';

/**
 * WYSIWYGify a textarea or div
 *
 *
 * @return {[type]} [description]
 * @param action
 * @param options
 */
$.fn.wysiwyg = function(action = "init", options = {}) {
	const self = this;
	if (action === "init") {
		/*
			set individual defered/promise for each element that could have been passed
		*/
		self.each(function(i, element){
			const dfd = Q.defer();
			$(element).data('tinymceEditorDfd', dfd);
			$(element).data('tinymceEditor', dfd.promise);
		});
		options = $.extend(true, {
			mode:		"exact",
			// elements:	[element],
			elements:   this.toArray(),
			themes:		"silver",
			plugins:	"advlist,image,link,lists,paste,media,code,codesample,autolink,table",
			menubar:	false,
			browser_spellcheck: true,
			statusbar:	false,
			link_context_toolbar: true,
			default_link_target: "_blank",
			image_description: false,	// alt text input
			image_dimensions: true,	// hide image dimension inputs
			onSetup : null,	// pass a function here to be called like setup
			paste_as_text: false,
			base_url: '/node_modules/tinymce',
            auto_focus: true,
			contextmenu: false,
			/* tinymce start */
			convert_urls: false,
			setup: function (editor) {
				$(editor.getElement()).data('tinymceEditorDfd').resolve(editor); // resolve the promise
				editor.on('change', function() {
					editor.save();
				});
				if (typeof options.onSetup == 'function') {
					options.onSetup.call(this, editor);
				}
			},
			// https://www.tinymce.com/docs/advanced/editor-control-identifiers/#toolbarcontrols
			toolbar: "undo | paste | fontselect fontsizeselect | fontfamily fontsize | " +
				"bold italic underline | backcolor forecolor | bullist numlist | " +
				"outdent indent | align | link image code codesample table",
			skin: false,
		}, options);
		self.each(function(i, element){
			const $element = $(element);
			if ($element.data("tinymceEditor").isFulfilled()) {
				// already initialized... lets remove it
				//    mceRemoveEditor updates the textarea value... we'll undo this
				//    this will allow $("#myEditor").val("new or empty value").wysiwyg()
				const editor = $element.data("tinymceEditor").inspect().value;
				const valBefore = $element.val();
				tinymce.EditorManager.execCommand('mceRemoveEditor', true, editor.id);
				$element.val(valBefore);
				// set new dfd/promise
				const dfd = Q.defer();
				$element.data('tinymceEditorDfd', dfd);
				$element.data('tinymceEditor', dfd.promise);
			} else {
				// lets see if editor instance exists
				// it's possible that textarea got removed/readded which causes tinymce to still think it's tinymceified
				const id = $element.prop("id");
				const editor = tinymce.get(id);
				if (editor) {
					tinymce.EditorManager.execCommand('mceRemoveEditor', true, editor.id);
				}
			}
		});
		tinymce.init(options);
	} else if (action === "clear") {
		self.each(function(i, element){
			$(element).data("tinymceEditor").then(function(editor){
				editor.setContent("");
			});
		});
	} else if (action === "remove") {
		self.each(function(i, element){
			var $element = $(element);
			if ($element.data("tinymceEditor") && $element.data("tinymceEditor").isFulfilled()) {
				console.warn('removing editor');
				var editor = $element.data("tinymceEditor").inspect().value;
				$(element).removeData(["tinymceEditor","tinymceEditorDfd"]);
				tinymce.EditorManager.execCommand('mceRemoveEditor', true, editor.id);
			}
		});
	}
	return this;
};
