(function($) {

	var Suggest = function(element, options) {
		var self = this;
		self.element = element;
		self.options = options;
		self.options.position = options.position ? options.position : { my: "left top", at: "left bottom", collision: "none" };
		self.defaultOptions = {
			minLength: 3,
			delay: 1000,
			source: function( request, response ) {
				params = $.extend((typeof(self.options.params) == 'function' ? self.options.params.call() : self.options.params) , {q: request.term});
				//select ajax or getJson
				// add a new parameter, request time
				var requestTime = new Date().getTime();
				// initialize global variable repairq to global(not a good idea)
				if(!window.repairq) {
					window.repairq = {};
					window.repairq.ajaxQueue = [];
				}
				window.repairq.latestRequestTime = requestTime;
				window.repairq.ajaxQueue.push(
					$.ajax({
						url: self.options.url,
						data: params,
						dataType : 'json',
						success: function(data) {
							// stop ajax success callback if an item is selected already
							// only accept lastest request
							self.element.removeClass('ui-autocomplete-loading') ;
							if(!self.alreadySelected && requestTime == window.repairq.latestRequestTime) {
								// kill all ajax calls
								length = window.repairq.ajaxQueue.length;
								for (i=0;i<length;i++) {
									window.repairq.ajaxQueue[i].abort();
								}
								window.repairq.ajaxQueue = [];
								if (typeof self.options.options.response == "function") {
									// response callback is a jquery-ui 1.9 thing
									// this "polyfill" serves our purposes
									self.options.options.response.call(self, data);
								}
								response(data);

								if(self.options.onSuccessResponse){
									self.options.onSuccessResponse.call(self,data);
								}
							}
						}
					})
				);
			},
			select: function( event, ui ) {
				event.preventDefault(); // prevent scroll-to-top from a link href="#"
				self.element.val( ui.item.name.replace(/<\/?[^>]+>/gi, '') );
				//$(self.options.valueContainer).val( ui.item.value );
				if (self.options.onSelect) {
					self.options.onSelect.call(self, ui);
				}
				return false;
			},
			focus: function( event, ui ) {
				//self.element.val( ui.item.label );
				if (self.options.onFocus) {
					self.options.onFocus.call(self, ui);
				}
				return false;
			},
			change: function(event, ui) {
				if (!ui.item) {
					$(self.options.valueContainer).val( '' );
				}
				if (self.options.onChange) {
					self.options.onChange.call(self, ui);
				}
			},
			position:self.options.position
		};
	};

	Suggest.prototype = {
		element: undefined,
		options: undefined,
		defaultOptions: undefined,

		init: function() {
			var self = this;
			$(self.element).autocomplete($.extend(self.defaultOptions, self.options.options))
			.data( "ui-autocomplete" )._renderItem = function( ul, item ) {
				ul.removeClass('ui-autocomplete ui-menu ui-widget ui-widget-content ui-corner-all');
				ul.addClass('ajax-suggestion-list typeahead dropdown-menu');
				var li = $("<li></li>");
				if (item.footer) {
					ul.css('height', 'auto');
					li.css('position', 'sticky');
					li.css('bottom', '-6px');
					li.css('margin-bottom', '-6px');
					li.css('width', 'auto');
					li.css('height', '60px');
					li.css('background-color', 'white');
					append = item.displayName;
				} else {
					if (typeof(item.displayName) !== 'undefined' && (item.name == '')) {
						append = "<p class='search-no-result'>" + (typeof(item.displayName) != 'undefined' ? item.displayName : item.name)  + "</p>";
					}else{
						if (typeof(item.name) != 'undefined') {
							append = "<a href='#'>" + (typeof(item.displayName) != 'undefined' ? item.displayName : item.name)  + "</a>";
						}else{
							append = "";
						}
					}
				}
				return li
					.data("item.autocomplete", item)
					.append(append)
					.addClass((typeof(item['class']) != 'undefined' ? item['class'] : ''))
					.appendTo(ul);
		
			};
		},
		
		_: ''
	};

	$.registry('suggest', Suggest, {
		methods: ['init'],
		defaults: {
			params: {},
			valueContainer: '',
			options: {}
		}
	});
})(jQuery);