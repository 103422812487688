// https://developer.mozilla.org/en/DOM/Storage#localStorage
// Here is another, less exact, imitation of the localStorage object.
// It is simpler than the previous one, but it is compatible with old browsers, like Internet Explorer < 8.
// It also makes use of cookies.



cookieStorage = {
	getItem: function (sKey) {
		if (!sKey || !this.hasOwnProperty(sKey)) {
			return null;
		}
		return unescape(document.cookie.replace(new RegExp("(?:^|.*;\\s*)" + escape(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*((?:[^;](?!;))*[^;]?).*"), "$1"));
	},
	key: function (nKeyId) {
		return unescape(document.cookie.replace(/\s*\=(?:.(?!;))*$/, "").split(/\s*\=(?:[^;](?!;))*[^;]?;\s*/)[nKeyId]);
	},
	setItem: function (sKey, sValue) {
		if (!sKey) {
			return;
		}
		var date = new Date();
		date.setTime(date.getTime()+(2*365*24*60*60*1000));
		document.cookie = escape(sKey) + "=" + escape(sValue) + "; expires="+date.toGMTString()+" path=/";
		this.length = document.cookie.match(/\=/g).length;
	},
	length: 0,
	removeItem: function (sKey) {
		if (!sKey || !this.hasOwnProperty(sKey)) {
			return;
		}
		var sExpDate = new Date();
		sExpDate.setDate(sExpDate.getDate() - 1);
		document.cookie = escape(sKey) + "=; expires=" + sExpDate.toGMTString() + "; path=/";
		this.length--;
	},
	hasOwnProperty: function (sKey) {
		return (new RegExp("(?:^|;\\s*)" + escape(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
	}
};

if (!window.localStorage) {
	window.localStorage = cookieStorage;
	window.localStorage.length = (document.cookie.match(/\=/g) || window.localStorage).length;
}